import React from 'react';
import { bool } from 'prop-types';
import SelectToggleFilterPlain from './SelectToggleFilterPlain';
import SelectToggleFilterPopup from './SelectToggleFilterPopup';

const SelectToggleFilter = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <SelectToggleFilterPopup {...rest} />
  ) : (
    <SelectToggleFilterPlain {...rest} />
  );
};

SelectToggleFilter.defaultProps = {
  showAsPopup: false,
};

SelectToggleFilter.propTypes = {
  showAsPopup: bool,
};

export default SelectToggleFilter;
