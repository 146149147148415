import React, { Component } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './SelectToggleFilterPopup.module.css';
const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectToggleFilterPopup extends Component {
  constructor(props) {
    super(props);

    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.setToggleOption = this.setToggleOption.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(queryParamName, option) {
    this.props.onSelect({ [queryParamName]: option });
  }

  setToggleOption(queryParamName, option) {
    this.props.onSelect({ [queryParamName]: option });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      additionalServices,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        {/* <button
          key={options?.[0]?.key}
          className={classNames(css.toggleItem, { [css.toggleItemSelected]: additionalServices === options?.[0].key || initialValue === options?.[0].key || additionalServices !== options?.[1].key })}
          onClick={() => this.selectOption(queryParamName, options?.[0]?.key)}
        >
          {options?.[0]?.label}
        </button> */}

        <button
          key={options?.[1]?.key}
          className={classNames(css.toggleItem, { [css.toggleItemSelected]: initialValue === additionalServices && initialValue === options?.[1].key })}
          onClick={() => this.selectOption(queryParamName, initialValue === options?.[1].key ? options?.[0].key : options?.[1].key)}
        >
          <span className={css.desktopLabel}>
            <FormattedMessage id="ListingPage.listingFullRates" />
          </span>
          <span className={css.mobileLabel}>
            <FormattedMessage id="ListingPage.listingFullRatesMobile" />
          </span>
        </button>

        <div
          className={classNames(css.toggle, { [css.toggleActive]: initialValue === additionalServices && initialValue === options?.[1].key })}
          onClick={() => this.setToggleOption(queryParamName, initialValue === options?.[1].key ? options?.[0].key : options?.[1].key)}
        />

      </div>
    );
  }
}

SelectToggleFilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectToggleFilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  contentPlacementOffset: number,
};

export default SelectToggleFilterPopup;
