import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import NamedLink from "../../../components/NamedLink/NamedLink";
import css from './NoSearchResultsMaybe.module.css';

const NoSearchResultsMaybe = props => {
  const { listingsAreLoaded, totalItems, location, resetAll, urlQueryParams, typeFilter } = props;
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;

  const itemType = urlQueryParams?.pub_itemType ?? null;

  const handleSaveToStorage = () => {
    sessionStorage.setItem("item_type", itemType);
  }

  const itemTypeLabel = typeFilter.length ? typeFilter[0].enumOptions.find(({ option }) => option === itemType) : "";

  return hasNoResult ? (
    <div className={css.noSearchResults}>
      <FormattedMessage id="SearchPage.noResults" />
      {itemType ? <>
        <FormattedMessage id="SearchPage.rentYour" values={{ type: itemTypeLabel ? itemTypeLabel.label : "" }} />
        <NamedLink name="NewListingPage" onClick={handleSaveToStorage} >
          <FormattedMessage id="SearchPage.createListing" />
        </NamedLink>
      </> : null}
      <br />
      {hasSearchParams ? (
        <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
          <FormattedMessage id={'SearchPage.resetAllFilters'} />
        </button>
      ) : null}
    </div>
  ) : null;
};

export default NoSearchResultsMaybe;
