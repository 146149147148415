import React from 'react';
import { bool, node, number, string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import css from './MainPanelHeader.module.css';

const MainPanelHeader = props => {
  const {
    rootClassName,
    className,
    children,
    resultsCount,
    searchInProgress,
    noResultsInfo,
    listingsWasUpdated
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {listingsWasUpdated && (
        <div className={css.searchResultSummary}>
            <span className={css.resultsNearBy}>
              <FormattedMessage id="SearchPage.nearByResults"/>
            </span>
        </div>
      )}
      {searchInProgress && (
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            <FormattedMessage id="MainPanelHeader.loadingResults" />
          </span>
        </div>
      )}
      <div className={css.searchOptions}>
        {!searchInProgress && (
          <div className={css.searchResultSummary}>
            <span className={css.resultsFound}>
              <FormattedMessage
                id="MainPanelHeader.foundResults"
                values={{ count: resultsCount }}
              />
            </span>
          </div>
        )}

        <div className={css.searchFilters}>

          {children}

          {/* {isSortByActive ? (
            <div className={css.sortyByWrapper}>
              <span className={css.sortyBy}>
                <FormattedMessage id="MainPanelHeader.sortBy" />
              </span>
              {sortByComponent}
            </div>
          ) : null} */}
        </div>
      </div>

      {noResultsInfo ? noResultsInfo : null}
    </div>
  );
};

MainPanelHeader.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

MainPanelHeader.propTypes = {
  rootClassName: string,
  className: string,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
};

export default MainPanelHeader;
